import React from "react";
import styled from "styled-components";

import Header from "../components/header";
import Card from "../components/Card/Card";
const MainWrap = styled.div`
  display: flex;
  padding-top: 0;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
const TitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px #888888;
  border-radius: 8px;
  width: 380px;
  height: 190px;
`;
const TitleLabel = styled.span``;
const CardListWrap = styled.div`
  background-color: #c4c4c4;
`;

const CardList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #c4c4c4;
`;

const data = [
  {
    title: "HP制作",
    svgKey: "hp-create"
  },
  {
    title: "デザイン制作",
    svgKey: "design-create"
  },
  {
    title: "マーケティング",
    svgKey: "marketing"
  },
  {
    title: "運用・保守",
    svgKey: "operation"
  }
];

const HpCreate = () => {
  return (
    <div>
      <Header />
      <MainWrap>
        <TitleWrap>各サービスページを背景にする</TitleWrap>
      </MainWrap>
      <CardListWrap>
        <CardList>
          {data.map(d => {
            return <Card key={d.title} title={d.title} svgKey={d.svgKey} />;
          })}
        </CardList>
      </CardListWrap>
    </div>
  );
};

export default HpCreate;
